<template>
  <div class="page-container w100p font-axiroma-regular" v-if="!loading">
    <h1 class="text-left mb20" >
      Current Status
      <span class="ml1 current-status-text f1-mb f1-5">{{ formatDate() }}</span>
    </h1>
    <div class="display-flex justify-between flex-wrap">
      <bh-status
        class="w48p-mb w31p"
        v-for="(status, index) in status"
        :key="status.key + index"
        :value="status.value"
        :icon="status.icon"
        :title="status.title"
        :backgroundColor="status.color"
        :primaryModel="primaryModel"
      />
    </div>
    <div
      class="display-flex flex-column-mb mt3 justify-between w100p flex-wrap-tb flex-column-tb flex-wrap-mb"
    >
      <bh-table
        sticky
        class="datatable-container w68p-dk w100p overflow-auto maxh65 text-no-wrap box-shadow box-shadow-light-dk maxh40-mb"
        enableSearch
        searchPlaceholder="Name or Location"
        headerClass="w25p-mb bg-violet-light"
        stickyColMob="name"
        :numberOfColumnsMob="1"
        :title="`Devices (${devices.length})`"
        :rows="getRows"
        :headers="headers"
        :tableLength="devices.length"
        @search="onSearch"
        @output="deviceClicked($event)"
      >
        <template v-slot:name="cell">
          <div class="text-left w100p">
            <bh-icon
              icon="ai-badge"
              class="f2 text-violet inline-block mr1"
              v-if="cell.data.modelBadge && primaryModel === 'Algo'"
            />
            <span
              class="h2 font-axiroma-bold pl0-5 mr1"
              v-if="cell.data.modelBadge && primaryModel === 'AI'"
            >
              <img
                src="/assets/algo_badge.svg"
                alt="algo and ai both preficted this alert"
                class="w2-5 vertical-align-bottom"
              />
            </span>
            <span
              class="h4 font-axiroma-bold text-center text-violet"
              v-if="!cell.data.aiBadge && primaryModel === 'Algo'"
              >&nbsp;</span
            >
            <span
              class="h4 align-center"
              :class="cell.data.class"
              :title="cell.data.originalValue || cell.data.value"
              >{{ cell.data.value }}</span
            >
          </div>
        </template>
      </bh-table>
      <div
        class="display-flex alerts-container flex-column flex-row-tb flex-wrap-tb justify-between mt0-dk mt2 w100p w30p-dk"
      >
        <bh-card
          class="display-flex align-center w48p-tb"
          v-for="(alert, index) in alerts"
          :class="{ mb1: index !== alerts.length - 1 }"
          :key="alert.title"
        >
          <bh-alerts
            :options="alertPieData(alert.value)"
            :title="alert.title"
            :value="alert.value"
            :legends="alert.legends"
          ></bh-alerts>
        </bh-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Alerts, Status } from '@/components/common'
import moment from '@/utils/moment'
import { status, alerts, tableHeaders } from '@/constants/dashboard'
import { getActiveEngines } from '@/services/performance'

export default {
  name: 'bh-dashboard',
  components: {
    'bh-alerts': Alerts,
    'bh-status': Status
  },
  data() {
    return {
      roomName: 'dashboard',
      searchTerm: '',
      alerts: [],
      status: [],
      headers: tableHeaders,
      devices: [],
      primaryModel: ''
    }
  },
  async mounted() {
    const engineData = await getActiveEngines({ where: {} })
    engineData.filter(data => {
      if (data.engineName === 'RE') {
        this.primaryModel = data.isPrimary ? 'Algo' : 'AI'
      }
    })
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapGetters('common/user', ['fullName']),
    getRows() {
      if (!this.searchTerm) return this.devices
      return this.devices.filter(({ name, location }) =>
        `${name.value} ${location.value}`
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      )
    }
  },
  sockets: {
    'dashboard-data': function(data) {
      this.setLoader(true)
      this.status = []
      const { statuses, devices } = data
      for (const s of status) {
        s.value = statuses[s.key]
        if (s.key === 'online') {
          s.value = `${statuses[s.key]}`
        }
        this.status.push(s)
      }
      for (const alert of alerts) {
        const { key, legends = [], value } = alert
        const alertsData = statuses[key] || {}
        alert.value = alertsData.total || value
        for (const legend of legends) {
          const { key, value: fallbackValue } = legend
          if (alertsData[key]) {
            const { status = 0, value = 0 } = alertsData[key]
            legend.value = value ?? fallbackValue
            legend.status = status
          }
        }
      }

      this.alerts = alerts

      const sortedDevicesData = devices.sort((a, b) => {
        let sortedValue =
            a.temperature?.is_alert_triggered === b.temperature?.is_alert_triggered
              ? 0
              : a.temperature?.is_alert_triggered
                ? -1
                : 1

        if (!sortedValue && sortedValue !== -1) {
          sortedValue =
            a.butane?.is_alert_triggered ===
            b.butane?.is_alert_triggered
              ? 0
              : a.butane?.is_alert_triggered
                ? -1
                : 1
        }

        if (!sortedValue && sortedValue !== -1) {
          sortedValue =
            a.sulphurDioxide?.is_alert_triggered ===
            b.sulphurDioxide?.is_alert_triggered
              ? 0
              : a.sulphurDioxide?.is_alert_triggered
                ? -1
                : 1
        }

        if (!sortedValue && sortedValue !== -1) {
          sortedValue =
          a.carbonDioxide?.is_alert_triggered ===
          b.carbonDioxide?.is_alert_triggered
            ? 0
            : a.carbonDioxide?.is_alert_triggered
              ? -1
              : 1
        }
        return sortedValue
      })

      this.devices = sortedDevicesData.map(device => {
        const {
          _id,
          // patient,
          // machine,
          // needs_attention,
          // needs_attention_ai,
          temperature,
          butane,
          sulphurDioxide,
          carbonDioxide,
          datetime,
          status_ai,
          status_re,
          attendedOn
        } = device
        // let location = `${machine.ward}`
        // if (machine.section) location += ` | ${machine.section}`
        // if (machine.bed) location += ` | ${machine.bed}`
        // if (!location) location = 'N/A'
        const deviceStatus =
          this.primaryModel === 'AI' ? status_ai : status_re
        let attendedDifference
        let isRecentlyAttended
        if (attendedOn) {
          const timeDiff =
            (new Date().getTime() - new Date(attendedOn * 1000).getTime()) /
            36e3
          const hours = Math.floor(timeDiff / 60)
            .toString()
            .padStart(2, 0)
          const minutes = Math.floor(timeDiff % 60)
            .toString()
            .padStart(2, 0)
          attendedDifference = `Attended ${hours}:${minutes} before`
          isRecentlyAttended = attendedOn && attendedDifference && hours < 3
        }
        return {
          deviceId: {
            value: _id,
            class: 'text-center capitalize',
            originalValue: '',
            deviceId: devices._id
          },
          // age: {
          //   value: moment.calculateAge(patient.date_of_birth),
          //   originalValue: moment.calculateAge(patient.date_of_birth),
          //   class: ''
          // },
          // location: {
          //   value: location,
          //   class: '',
          //   originalValue: location
          // },
          needs_attention: {
            value: !deviceStatus ? 'N/A' : deviceStatus,
            class:
              deviceStatus === 'Needs Attention'
                ? 'needs-attention status pa1 br0-7'
                : 'normal status pa1 br0-7',
            originalValue: deviceStatus,
            tooltipContent: isRecentlyAttended ? attendedDifference : '',
            deviceId: device._id
          },
          temperature: {
            value: temperature?.value !== -1 ? `${temperature?.value.toFixed(2)} ˚ C` : 'null',
            class: temperature?.is_alert_triggered ? 'text-danger' : '',
            originalValue: temperature?.value
          },
          butane: {
            value:
              butane?.value !== -1
                ? `${butane?.value.toFixed(2)} ppm`
                : 'null',
            class: butane?.is_alert_triggered
              ? 'text-danger'
              : '',
            originalValue: butane?.value
          },
          sulphurDioxide: {
            value:
              sulphurDioxide?.value !== -1
                ? `${sulphurDioxide.value.toFixed(2)} ppm`
                : 'null',
            class: sulphurDioxide?.is_alert_triggered
              ? 'text-danger'
              : '',
            originalValue: sulphurDioxide?.value
          },
          carbonDioxide: {
            value:
              carbonDioxide?.value !== -1
                ? `${carbonDioxide.value.toFixed(2)} ppm`
                : 'null',
            class: carbonDioxide?.is_alert_triggered
              ? 'text-danger'
              : '',
            originalValue: carbonDioxide?.value
          },
          datetime: {
            value: moment.fromNow(datetime * 1000),
            class: '',
            originalValue: datetime
          }
        }
      })

      this.setLoader(false)
    }
  },
  created() {
    if (this.$socket.disconnected) {
      this.$socket.connect()
    }
    this.setLoader(true)
    const filter = {
      fields: {
        patient: 1,
        deviceId: 1,
        machine: 1,
        needs_attention: 1,
        needs_attention_ai: 1,
        temperature: 1,
        butane: 1,
        sulphurDioxide: 1,
        carbonDioxide: 1,
        datetime: 1,
        status_ai: 1,
        status_re: 1,
        attendedOn: 1
      }
    }
    this.$socket.emit('join', { room: this.roomName, filter })
    // const beds = await getAllBeds()
  },
  methods: {
    ...mapActions(['setLoader']),
    /**
     * Formatts the time
     * @method deviceClicked
     * @param - deviceData
     */
    deviceClicked(deviceData) {
      this.$router.push({
        name: 'DeviceDetail',
        params: { id: deviceData?.needs_attention?.deviceId }
      })
    },
    alertPieData(value) {
      return {
        series: [
          {
            name: 'Alerts',
            type: 'pie',
            radius: ['85%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center'
            },
            labelLine: {
              show: true
            },
            data: [
              {
                hoverAnimation: false,
                value,
                itemStyle: { color: '#5764B3' },
                name: `${value} Alerts`
              },
              {
                hoverAnimation: false,
                value: value > 99 ? 100 : 100 - value,
                itemStyle: {
                  color: '#fff',
                  shadowColor: 'rgba(0, 0, 0, 0.3)',
                  shadowBlur: 2
                }
              }
            ]
          }
        ]
      }
    },
    /**
     * Formatts the time
     * @method formatDate
     * @param - { Object }
     */
    formatDate() {
      return moment.formatDate(new Date().toISOString(), 'DD MMMM YYYY')
    },
    /**
     * Callback for search
     * @method onSearch
     * @param - searchTerm - { String }
     */
    onSearch(searchTerm) {
      this.searchTerm = searchTerm
    }
  },
  beforeDestroy() {
    if (this.$socket) {
      this.$socket.disconnect()
    }
  }
}
</script>

<style scoped>
.alerts-card-container:first-child {
  margin-top: 0rem;
}

.status {
  border-radius: 7px;
  padding: 0.3rem;
}

.normal {
  background-color: #dafdd3;
  color: #1cb500;
}

h1 {
  font-size: x-large;
}

a,
i {
  font-size: 2rem;
}

.current-status-text {
  color: #959595;
}

@media (min-width: 320px) and (max-width: 767px) {
  .dashboard-container {
    margin: 0rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .alerts-card-container:first-child {
    margin-top: 2.5rem;
  }
}
</style>
